<template>
	<v-navigation-drawer
		v-model="dialog"
		absolute
		right
		light
		app
		temporary
		width="544px"
		@input="watchDrawer"
	>
		<v-app-bar flat color="primary" height="75" absolute width="100%">
			<div class="header-drawer d-flex justify-space-between align-center">
				<span>Cadastrar nova pendência</span>

				<v-btn color="#fff" fab icon @click="setMask" class="button">
					<v-icon>close</v-icon>
				</v-btn>
			</div>
			<template v-slot:extension>
				<v-tabs fixed-tabs v-model="tab" background-color="#fff">
					<v-tab>
						INFORMAÇÕES PRINCIPAIS
					</v-tab>
					<v-tab>
						INFORMAÇÕES ADICIONAIS
					</v-tab>
				</v-tabs>
			</template>
		</v-app-bar>

		<v-tabs-items v-model="tab" style="margin-top: 120px;">
			<v-tab-item>
				<p class="description">
					Preencha as informações abaixo para cadastrar uma nova pendência.
				</p>
				<FormPendency />
			</v-tab-item>
			<v-tab-item>
				<AdditionalInformation />
			</v-tab-item>
		</v-tabs-items>
	</v-navigation-drawer>
</template>

<script>
import FormPendency from '@/components/unique/Dashboard/FormPendency'
import AdditionalInformation from '@/components/unique/Dashboard/AdditionalInformation'

import overlayDrawer from '@/mixins/overlayDrawer'

export default {
	data: () => ({
		load: false,
		tab: null,
	}),
	components: {
		FormPendency,
		AdditionalInformation,
	},
	mixins: [overlayDrawer],
}
</script>

<style lang="scss" scoped>
.header-drawer {
	width: 100%;
	span {
		color: #fff;
	}
}

.title {
	color: #fff !important;
	font-weight: 500;
	line-height: 32px;
}

.description {
	width: 90%;
	margin-top: 70px;
	padding: 32px 0 0 20px;
}
</style>

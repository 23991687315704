<template>
	<v-form class="form">
		<v-card-title class="form__action  ">
			INFORMAÇÕES GERAIS

			<v-switch v-model="form.switch1" :label="`Reicidente`" />
		</v-card-title>
		<v-row>
			<v-col cols="12" md="6">
				<v-text-field
					label="Pendencia de Origem"
					required
					hide-details
					v-model="form.origin"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Nº Função, Desc Func ou ASP"
					required
					hide-details
					v-model="form.functionDesc"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="N° Chamado Cliente"
					required
					hide-details
					v-model="form.clientNumber"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Nome do Pacote"
					required
					hide-details
					v-model="form.packageName"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-container fluid>
				<v-textarea
					hide-details
					name="input-7-1"
					filled
					label="Motivo da urgência"
					auto-grow
					v-model="form.urgency"
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-container>
		</v-row>

		<v-divider />

		<v-row>
			<v-col md="12"
				><v-card-title class="form__action  ">
					HORAS PLANEJADAS
				</v-card-title></v-col
			>
			<v-col cols="12" md="3">
				<v-text-field
					label="Definição"
					required
					hide-details
					v-model="form.definition"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="3" class="colunm">
				<v-text-field
					label="Dev média"
					required
					hide-details
					v-model="form.devMedia"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="3" class="colunm">
				<v-text-field
					label="Teste"
					required
					hide-details
					v-model="form.teste"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="3" class="colunm">
				<v-text-field
					label="Adicionais"
					required
					hide-details
					v-model="form.aditionals"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
		</v-row>

		<v-divider />

		<v-row>
			<v-col md="12"
				><v-card-title class="form__action  ">
					DATA
				</v-card-title></v-col
			>

			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu1"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.solicitationDate"
							label="Data da Solicitação"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.solicitationDate = null"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.solicitationDate"
						range
						no-title
						@input="form.menu1 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu2"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.deliveryDate"
							label="Data de Entrega"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.deliveryDate = null"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.deliveryDate"
						range
						no-title
						@input="form.menu2 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu3"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.expectedClient"
							label="Prevista Cliente"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.expectedClient = null"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.expectedClient"
						range
						no-title
						@input="form.menu3 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu4"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.okClient"
							label="Ok Cliente"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.okClient = null"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.okClient"
						range
						no-title
						@input="form.menu4 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu5"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.expectedInternal"
							label="Prevista Interna"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.expectedInternal = null"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.expectedInternal"
						range
						no-title
						@input="form.menu5 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu6"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.definition"
							label="Período de Definição"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.definition = []"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.definition"
						range
						no-title
						@input="form.menu6 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu7"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.developmentDate"
							label="Período de Desenv"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.developmentDate = []"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.developmentDate"
						range
						no-title
						@input="form.menu7 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu8"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.testsPeriod"
							label="Período de Testes"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.testsPeriod = []"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.testsPeriod"
						range
						no-title
						@input="form.menu8 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="form.menu9"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="form.homologDate"
							label="Data de Hemolog"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="form.homologDate = null"
							background-color="rgba(0, 0, 0, 0.03)"
						/>
					</template>
					<v-date-picker
						v-model="form.homologDate"
						range
						no-title
						@input="form.menu9 = false"
					></v-date-picker>
				</v-menu>
			</v-col>
		</v-row>

		<v-divider />

		<v-row>
			<v-col md="12">
				<v-card-title class="form__action ">
					RESPONSÁVEL
				</v-card-title>
			</v-col>
			<v-col cols="12" sm="6" md="6">
				<v-autocomplete
					v-model="form.analysis"
					:items="item"
					filled
					label="Análise"
					background-color="rgba(0, 0, 0, 0.03)"
					hide-details
				/>
			</v-col>
			<v-col cols="12" sm="6" md="6">
				<v-autocomplete
					v-model="form.development"
					:items="item"
					filled
					label="Desenvolvimento"
					background-color="rgba(0, 0, 0, 0.03)"
					hide-details
				/>
			</v-col>
			<v-col cols="12" sm="6" md="6">
				<v-autocomplete
					v-model="form.test"
					:items="item"
					filled
					label="Teste"
					background-color="rgba(0, 0, 0, 0.03)"
					hide-details
				/>
			</v-col>
		</v-row>

		<v-divider />

		<v-row>
			<v-col md="12">
				<v-card-title class="form__action ">
					OUTRAS INFORMAÇÕES
				</v-card-title>
			</v-col>
			<v-col cols="12" sm="6" md="6">
				<v-autocomplete
					v-model="form.exibition"
					:items="item"
					filled
					label="Exibição"
					background-color="rgba(0, 0, 0, 0.03)"
					hide-details
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-autocomplete
					label="Diagnóstico"
					:items="item"
					required
					hide-details
					v-model="form.diagnostic"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Dificuldade"
					required
					hide-details
					v-model="form.difficulty"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="N° Prioridade"
					required
					hide-details
					v-model="form.numberPriority"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Prioridade Cliente"
					required
					hide-details
					v-model="form.clientPriority"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Grupo Pendência"
					required
					hide-details
					v-model="form.pendencyGroup"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Versão"
					required
					hide-details
					v-model="form.version"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="N° da Fatura"
					required
					hide-details
					v-model="form.invoice"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					label="Documento de Apoio"
					required
					hide-details
					v-model="form.document"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-col>
		</v-row>

		<v-divider />

		<v-row>
			<v-col md="12" class="form__action "
				><v-card-title class="pb-0">
					CAUSA / SOLUÇÃO
				</v-card-title>
			</v-col>
			<v-col md="12">
				<span class="subtitle">
					Tipo de solução
				</span>
			</v-col>

			<v-radio-group v-model="row" row class="pl-3">
				<v-radio label="Paliativa" value="paliativa"></v-radio>
				<v-radio label="Definitiva" value="definitiva"></v-radio>
			</v-radio-group>
		</v-row>
		<v-row>
			<v-container fluid>
				<v-textarea
					hide-details
					name="input-7-1"
					filled
					label="Informe a causa"
					auto-grow
					v-model="form.cause"
					row-height="12"
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-container>
			<v-container fluid>
				<v-textarea
					class="pb-3"
					hide-details
					name="input-7-1"
					filled
					label="Informe a Solução"
					auto-grow
					v-model="form.solution"
					row-height="12"
					background-color="rgba(0, 0, 0, 0.03)"
				/>
			</v-container>
		</v-row>
		<v-col class="d-flex justify-end pa-0">
			<v-btn
				outlined
				color="primary"
				class="button ml-3"
				elevation="0"
				height="36"
				width="83"
				>Limpar</v-btn
			>
			<v-btn
				class="text-center ml-3 "
				color="#1e90ff"
				dark
				depressed
				height="36"
				width="83"
				type="submit"
			>
				SALVAR
			</v-btn>
		</v-col>
	</v-form>
</template>

<script>
export default {
	data() {
		return {
			item: ['teste', 'teste2'],
			form: {
				switch1: false,
				origin: '',
				functionDesc: '',
				clientNumber: '',
				packageName: '',
				urgency: '',
				definition: '',
				devMedia: '',
				teste: '',
				aditionals: '',
				menu1: false,
				solicitationDate: '',
				menu2: false,
				deliveryDate: '',
				menu3: false,
				expectedClient: '',
				menu4: false,
				okClient: '',
				menu5: false,
				expectedInternal: '',
				menu6: false,
				definition: [],
				menu7: false,
				developmentDate: [],
				menu8: false,
				testsPeriod: [],
				menu9: false,
				homologDate: '',
				analysis: '',
				development: '',
				test: '',
				exibition: '',
				diagnostic: '',
				difficulty: '',
				numberPriority: '',
				clientPriority: '',
				pendencyGroup: '',
				version: '',
				invoice: '',
				document: '',
				origin: '',
				cause: '',
				solution: '',
			},
			row: null,
		}
	},
}
</script>

<style lang="scss" scoped>
.form {
	padding: 20px;
	&__action {
		display: flex;
		justify-content: space-between;
		padding: 0;
	}
}
.colunm {
	padding-left: 0;
}
.subtitle {
	font-weight: 600;
	font-size: 16px;
	padding-left: 5px;
}
.button {
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
}
</style>
